import React, { useEffect } from "react"

import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"

//import the Prism package
import Prism from "prismjs"

import "./blog-post.css"

export default function BlogPost({ data }) {
  useEffect(() => {
    // call the highlightAll() function to style our code blocks
    Prism.highlightAll()
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  }, [])
  const post = data.allWpPost.nodes[0]

  return (
    <Layout>
      <article>
        <h1>{post.title}</h1>
        <span className="post-author-date">
          {post.date} · by {post.author.node.name}
        </span>
        <Img
          fluid={post.featuredImage.node.localFile.childImageSharp.fluid}
          alt={post.title}
        />
        <div key={post.id} style={{ margin: "25px 0" }}>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-8676170233811690"
            data-ad-slot="4567248565"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </article>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        status
        slug
        title
        content
        uri
        excerpt
        date(formatString: "MMM DD, YYYY")
        author {
          node {
            name
            slug
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
